import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'guards/auth.guard';

// TODO create auth module
const unauthenticatedRoutes: Routes = [
  {
    path: 'app-housekeepers/login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'app-housekeepers/reset-password/:token',
    loadChildren: () =>
      import('./pages/reset-password-finish/reset-password-finish.module').then(m => m.ResetPasswordFinishPageModule)
  },
  {
    path: 'app-housekeepers/reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'app-housekeepers/onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingPageModule)
  },
  {
    path: 'app-housekeepers/get-started/:applicantId/:token',
    redirectTo: '/app-housekeepers/onboarding/welcome/:applicantId/:token'
  }
];

const authenticatedRoutes: Routes = [
  {
    path: 'app-housekeepers/id',
    loadChildren: () => import('./pages/badge/badge.module').then(m => m.BadgePageModule)
  },
  {
    path: 'app-housekeepers/cleaning-guides',
    loadChildren: () => import('./pages/cleaning-guides/cleaning-guides.module').then(m => m.CleaningGuidesPageModule)
  },
  {
    // TODO move this to the help module
    path: 'app-housekeepers/help/chat-to-housekeep',
    loadChildren: () =>
      import('./pages/chat-to-housekeep/chat-to-housekeep.module').then(m => m.ChatToHousekeepPageModule)
  },
  {
    // TODO move this to the help module
    path: 'app-housekeepers/help/closed-help-requests',
    loadChildren: () =>
      import('./pages/closed-help-requests/closed-help-requests.module').then(m => m.ClosedHelpRequestsPageModule)
  },
  {
    // TODO move this to the help module
    path: 'app-housekeepers/help/open-help-requests',
    loadChildren: () =>
      import('./pages/open-help-requests/open-help-requests.module').then(m => m.OpenHelpRequestsPageModule)
  },
  {
    // TODO move this to the help module
    path: 'app-housekeepers/help/contact/:helpCategory',
    loadChildren: () => import('./pages/contact-page-base/contact-page-base.module').then(m => m.ContactBasePageModule)
  },
  {
    // TODO move this to the help module
    path: 'app-housekeepers/help/contact',
    loadChildren: () => import('./pages/contact-page-base/contact-page-base.module').then(m => m.ContactBasePageModule)
  },
  {
    // TODO move this to the help module
    path: 'app-housekeepers/help/help-request-activity',
    loadChildren: () =>
      import('./pages/help-request-activity/help-request-activity.module').then(m => m.HelpRequestActivityPageModule)
  },
  {
    path: 'app-housekeepers/help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'app-housekeepers/customer-comms',
    loadChildren: () => import('./pages/customer-comms/customer-comms.module').then(m => m.CustomerCommsPageModule)
  },
  {
    path: 'app-housekeepers/extra-jobs',
    loadChildren: () => import('./pages/extra-jobs/extra-jobs.module').then(m => m.ExtraJobsPageModule)
  },
  {
    // TODO move this to the history module
    path: 'app-housekeepers/payments/period/:periodId',
    loadChildren: () => import('./pages/history-period/history-period.module').then(m => m.HistoryPeriodPageModule)
  },
  {
    path: 'app-housekeepers/payments',
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule)
  },
  {
    path: 'app-housekeepers/legal',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule)
  },

  {
    path: 'app-housekeepers/news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'app-housekeepers/performance',
    loadChildren: () => import('./pages/performance/performance.module').then(m => m.PerformancePageModule)
  },
  {
    path: 'app-housekeepers/permissions',
    loadChildren: () => import('./pages/permissions/permissions.module').then(m => m.PermissionsPageModule)
  },
  {
    // TODO move this to the profile module
    path: 'app-housekeepers/profile/add-hours',
    loadChildren: () =>
      import('./pages/profile-add-hours/profile-add-hours.module').then(m => m.ProfileAddHoursPageModule)
  },
  {
    // TODO move this to the profile module
    path: 'app-housekeepers/profile/contact-details',
    loadChildren: () =>
      import('./pages/profile-contact-details/profile-contact-details.module').then(
        m => m.ProfileContactDetailsPageModule
      )
  },
  {
    // TODO move this to the profile module
    path: 'app-housekeepers/profile/customer-keys',
    loadChildren: () =>
      import('./pages/profile-customers-keys/profile-customers-keys.module').then(m => m.ProfileCustomersKeysPageModule)
  },
  {
    // TODO move this to the profile module
    path: 'app-housekeepers/profile/hours',
    loadChildren: () => import('./pages/profile-hours/profile-hours.module').then(m => m.ProfileHoursPageModule)
  },
  {
    // TODO move this to the profile module
    path: 'app-housekeepers/profile/payment-details',
    loadChildren: () =>
      import('./pages/profile-payment-details/profile-payment-details.module').then(
        m => m.ProfilePaymentDetailsPageModule
      )
  },
  {
    // TODO move this to the profile module
    path: 'app-housekeepers/profile/postcodes',
    loadChildren: () =>
      import('./pages/profile-postcodes/profile-postcodes.module').then(m => m.ProfilePostcodesPageModule)
  },
  {
    // TODO move this to the profile module
    path: 'app-housekeepers/profile/travel',
    loadChildren: () => import('./pages/profile-travel/profile-travel.module').then(m => m.ProfileTravelPageModule)
  },
  {
    path: 'app-housekeepers/profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'app-housekeepers/profile/job-settings',
    loadChildren: () =>
      import('./pages/profile-job-settings/profile-job-settings.module').then(m => m.JobSettingsPageModule)
  },
  {
    path: 'app-housekeepers/time-off',
    loadChildren: () => import('./pages/profile-time-off/profile-time-off.module').then(m => m.ProfileTimeOffPageModule)
  },
  {
    path: 'app-housekeepers/refer',
    loadChildren: () =>
      import('./pages/profile-referrals/profile-referrals.module').then(m => m.ProfileReferralsPageModule)
  },
  {
    // TODO move this to the schedule module
    path: 'app-housekeepers/schedule/visit/:jobId/:scheduledDate/:actualDate/:tab',
    loadChildren: () => import('./pages/schedule-visit/schedule-visit.module').then(m => m.ScheduleVisitPageModule)
  },
  {
    // TODO move this to the schedule module
    path: 'app-housekeepers/schedule/visit/:jobId/:scheduledDate/:actualDate',
    loadChildren: () => import('./pages/schedule-visit/schedule-visit.module').then(m => m.ScheduleVisitPageModule)
  },
  {
    path: 'app-housekeepers/schedule-list',
    loadChildren: () => import('./pages/schedule-list/schedule-list.module').then(m => m.ScheduleListPageModule)
  },
  {
    path: 'app-housekeepers/schedule',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule)
  },
  {
    // TODO move this to the schedule module
    path: 'app-housekeepers/schedule/day/:date',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule)
  },
  {
    path: 'app-housekeepers/schedule/show/menu',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule)
  },
  {
    path: 'app-housekeepers/whats-new',
    loadChildren: () => import('./pages/whats-new/whats-new.module').then(m => m.WhatsNewPageModule)
  },
  {
    path: 'app-housekeepers/whats-next',
    loadChildren: () => import('./pages/whats-next/whats-next.module').then(m => m.WhatsNextPageModule)
  },
  {
    path: 'app-housekeepers/worker-comms',
    loadChildren: () => import('./pages/worker-comms/worker-comms.module').then(m => m.WorkerCommsPageModule)
  },
  {
    path: 'app-housekeepers/achievements',
    loadChildren: () => import('./pages/achievements/achievements.module').then(m => m.AchievementsPageModule)
  }
].map((route: Route) => ({ ...route, canActivate: [AuthGuard] }));

const legacyRedirects: Routes = [
  {
    path: 'reliability',
    redirectTo: 'app-housekeepers/performance/reliability',
    pathMatch: 'full'
  }
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app-housekeepers/schedule',
    pathMatch: 'full'
  },
  {
    path: 'app-housekeepers',
    redirectTo: 'app-housekeepers/schedule',
    pathMatch: 'full'
  },
  ...unauthenticatedRoutes,
  ...authenticatedRoutes,
  ...legacyRedirects,
  {
    path: '**',
    loadChildren: () => import('./pages/error/page-not-found.module').then(m => m.PageNotFoundPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
