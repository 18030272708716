import { Environment } from 'interfaces/environment';
import { Level } from 'interfaces/verbosity';

export const stagingEnvironment: Environment = {
  ID: 'staging',
  CONSOLE_VERBOSITY: Level.Debug,
  API_ROOT: 'https://staging.housekeep.com/api/v1/',
  WEB_ROOT: 'https://staging.housekeep.com/',
  APPLE_APP_ID: 'id1232576549',
  HOUSEKEEP_TWITTER_USER_NAME: 'housekeep',
  PACKAGE_NAME: 'com.housekeep.housekeepersapp',
  SENTRY_DSN: 'https://6ffd4ab5749c4a01b0ca840ee1c38101@sentry.io/156974',
  GOOGLE_MAPS_API_KEY: 'AIzaSyAQAZEciO3D0UoFO3nZ5GYkn4HSL9ydGU8',
  ZENDESK_PUBLIC_ACCOUNT_KEY: '4cxtBovEbQQkhJKPxWWxrQOBfI1oTHip',
  NEXT_RELEASE_DATE: '2024-12-18'
};
