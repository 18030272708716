import { Environment } from 'interfaces/environment';
import { Level } from 'interfaces/verbosity';

export const prodEnvironment: Environment = {
  ID: 'prod',
  CONSOLE_VERBOSITY: Level.NoConsole,
  API_ROOT: 'https://housekeep.com/api/v1/',
  WEB_ROOT: 'https://housekeep.com/',
  APPLE_APP_ID: 'id1232576549',
  HOUSEKEEP_TWITTER_USER_NAME: 'housekeep',
  PACKAGE_NAME: 'com.housekeep.housekeepersapp',
  SENTRY_DSN: 'https://571c5654b05b4b1abf6d331dc1b05e0d@sentry.io/156973',
  GOOGLE_MAPS_API_KEY: 'AIzaSyAQAZEciO3D0UoFO3nZ5GYkn4HSL9ydGU8',
  ZENDESK_PUBLIC_ACCOUNT_KEY: 'bGsvV2iqF9dLn5oScdrf16d2HAA7TNhP',
  NEXT_RELEASE_DATE: '2024-12-18'
};
