import { WhatsNewSlide } from '../../whats-new-service';

export const LATEST_VERSION = '3.20.0';

const IMAGE_BASE_PATH = `/releases/${LATEST_VERSION}/whats-new/`;

/**
 * Return an image URL for the given filename, relative to the image base path.
 * @param filename
 */
function getImageUrl(filename): string {
  return `https://app-housekeepers.housekeep.com${IMAGE_BASE_PATH}${filename}`;
}

export const LATEST_SLIDES: WhatsNewSlide[] = [
  {
    format: 'fullBlue',
    title: '',
    description: '',
    imageUri: getImageUrl('1.png'),
    imageStyles: {
      'max-width': '300px'
    }
  },
  {
    format: 'split',
    title: "We've improved the Help options to make it easier for jobs to go ahead successfully.",
    description: ``,
    imageUri: getImageUrl('2.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Share your arrival time',
    description: `
      <p>
        Let customers know when you're on the way and your arrival time to ensure they're ready to let you in.
      </p>
    `,
    imageUri: getImageUrl('3.gif'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: "If you're ever stuck outside",
    description: `
      <p>Tap 'Help' within the job, then 'I'm stuck outside'.</p>
      <p>This is the quickest way to ensure the job goes ahead.</p>
    `,
    imageUri: getImageUrl('4.gif'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Contact your customer',
    description: `
      <p>Send the 'Stuck outside' chat message to your customer and call them.</p>
      <p>If they don't answer or reply, try calling again.</p>
    `,
    imageUri: getImageUrl('5.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Stuck outside timer',
    description: `
      <p>When you let your customer know you're stuck, a timer will track how long you're trying to access the property.</p>
      <p>Try for at least 15 minutes.</p>
    `,
    imageUri: getImageUrl('6.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: "If you still can't start your clean",
    description: `
      <p>We'll help you get inside so the job can go ahead.</p>
      <p>You must be at the property when you ask Housekeep to help.</p>
    `,
    imageUri: getImageUrl('7.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'We hope you like this update!',
    description: `
      <p>We've made some bug fixes and other improvements so that the app is easier to use.</p>
      <p>Let the team know if you have any app issues.</p>
    `,
    imageUri: getImageUrl('8.png'),
    imageStyles: {
      'max-width': '300px'
    }
  }
];
