// CORONAVIRUS
export const FEATURE_CORONAVIRUS_CLOSED = 'coronavirus-hkapp-closed';
export const FEATURE_CORONAVIRUS_LINK_IN_HELP = 'coronavirus-hkapp-link-help';
export const FEATURE_CORONAVIRUS_LINK_IN_MENU = 'coronavirus-hkapp-link-menu';
export const FEATURE_CORONAVIRUS_PRECAUTIONS = 'coronavirus-hkapp-precautions';

// ONBOARDING
export const FEATURE_ONBOARDING_AVAILABLE_SUNDAYS = 'worker-onboarding-available-sundays';
export const FEATURE_ONBOARDING_NO_REGULAR_HOURS = 'worker-onboarding-no-regular-hours';

// TIME OFF MESSAGE
export const TIME_OFF_MESSAGE_REQUIRED = 'time-off-message-required';

// HOUSEKEEP ACADEMY
export const FEATURE_HOUSEKEEP_ACADEMY = 'worker-housekeep-academy-link';

// VISIT PAGE
export const FEATURE_VISIT_NEED_MORE_TIME = 'worker-visit-need-more-time';

// EXTRA JOBS PAGE
export const EXTRA_REGULAR_JOBS_LOCK_EXEMPTION = 'exempt-extra-regular-jobs-lock';

// Use the recommended start timeslot for schedule display
export const SHOW_RECOMMENDED_START = 'worker-recommended-start-timeslot';

// Consent worker to call recording
export const CONTACT_CONSENT_TO_CALL_RECORDING = 'contact-consent-to-call-recording';

// Check if the worker can see the chat media
export const CHAT_MEDIA_AVAILABLE = 'chat-media-available';

// Check if extra tags should be added to a Zendesk chat
export const ADD_TICKET_TAGS_TO_CHAT = 'worker-add-ticket-tags-to-chat';

// Alters the latest time that unassigned jobs and visits can be assigned to this worker for the following day.
export const LATER_ASSIGNMENT_CUT_OFF = 'worker-later-assignment-cut-off';
//
// Allow/Disallow Housekeepers to turn on late assignments for themselves
export const LATER_ASSIGNMENT_OPS_BLOCK = 'worker-later-assignment-ops-block';

// Check if job settings page can be displayed in the profile page list
export const ENABLE_JOB_SETTINGS_PAGE = 'worker-enable-job-settings-page';

// Check if add hours page can be displayed in the profile page list
export const ENABLE_ADD_HOURS_PAGE = 'worker-enable-add-hours-page';
